import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import AuthLayout, {
  Welcome,
  Logo,
  InfoText,
} from '../../components/AuthLayout';
import {
  Form,
  TextInput,
  Button,
  Spin,
  Icon,
  Checkbox,
  Modal,
  styled,
} from 'ykb-ui';
import backgroundImage from './qr_code.png';
import callApi from '../../utils/callApi';

export default function DocumentVerification() {
  const formRef = useRef();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [refreshToken, setRefreshToken] = useState(0);
  const [isVerified, setIsVerified] = useState(false);
  const [showFirstText, setShowFirstText] = useState(false);
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState(false);

  const id = history.location.pathname
    .replace('/belge-dogrulama', '')
    .replace('/', '');

  const CheckboxWithPadding = styled(Checkbox)`
    padding: 10px 0;
  `;

  function refreshCaptcha() {
    setRefreshToken(refreshToken + 1);
  }

  useEffect(() => {
    if (id && formRef.current) {
      formRef.current.setFieldsValue({
        qRCode: id,
      });
    }
  }, []);

  async function submitForm(e, error, values) {
    e.preventDefault();
    if (!error) {
      try {
        setLoading(true);
        const response = await callApi({
          endpoint: 'DocumentVerification/GetVerificationDocument',
          body: {
            isDownload: window.innerWidth >= 1024,
            ...values,
          },
          file: true,
          method: 'POST',
        });
        if (response) {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(response);
          if (window.innerWidth >= 1024) {
            setFile(link.href);
            setVisible(true);
          } else {
            link.download = 'qr_document';
            link.click();
          }
        }
      } finally {
        refreshCaptcha();
        setLoading(false);
      }
    }
  }

  function toogleCheckbox() {
    setIsVerified(!isVerified);
  }

  const closeModal = () => {
    setVisible(false);
  };

  function validateCheckbox(rule, value, callback) {
    if (value !== true) {
      callback([new Error('Kabul etmeniz gerekmektedir.')]);
    } else {
      callback();
    }
  }

  return (
    <AuthLayout image={backgroundImage}>
      <Helmet>
        <title>Kare kod ile belge doğrulama</title>
      </Helmet>
      <Logo alt="Yapı ve Kredi Bankası A.Ş. Emekli Sandığı Vakfı" />
      <Welcome>Kare Kod İle Belge Doğrulama</Welcome>
      <InfoText>
        Görüntülemek istediğiniz belgenin alt kısmında yer alan QR kod
        numarasını girmelisiniz..
      </InfoText>
      <Spin spinning={loading}>
        <Form
          ref={formRef}
          layout="vertical"
          onSubmit={submitForm}
          colSpan={{ xs: 24 }}
        >
          <Form.Item label={'Kare Kod'}>
            <TextInput
              name="qRCode"
              focus={true}
              autoFocus={true}
              maxLength={30}
              validationOptions={{ validateTrigger: ['onSubmit'] }}
            />
          </Form.Item>
          <Form.Item label={'Güvenlik Kodu'}>
            <img
              alt="captcha"
              src={`${process.env.REACT_APP_API}/Auth/GetCaptchaImage?${refreshToken}`}
              style={{ height: '40px' }}
            />
            <TextInput
              name="captcha"
              validation={[{ required: true }]}
              validationOptions={{ validateTrigger: ['onSubmit', 'onBlur'] }}
              addonAfter={
                <Button onClick={refreshCaptcha}>
                  <Icon name="refresh" />
                </Button>
              }
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item>
            <CheckboxWithPadding
              name="text"
              validation={[
                {
                  validator: validateCheckbox,
                },
              ]}
              onChange={toogleCheckbox}
              checked={isVerified}
            >
              <Button
                link="#"
                onClick={e => {
                  e.preventDefault();
                  setShowFirstText(true);
                }}
              >
                Bilgilendirme Yazısı
              </Button>
              'nı okudum, onaylıyorum.
            </CheckboxWithPadding>
          </Form.Item>

          <Form.Item label={''}>
            <Button
              className="form-button"
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
            >
              Devam Et
            </Button>
          </Form.Item>
        </Form>
        <Modal
          title={'Bilgilendirme Yazısı'}
          visible={showFirstText}
          onClose={() => setShowFirstText(false)}
        >
          <p>
            <em>
              <span>
                1. &nbsp; &nbsp;Bu hizmet, Yapıkredi Emekli Sandığı Vakfı
                tarafından sunulan Vakıf ve Online Portal hizmeti kullanılarak
                ilgili şahıs tarafından alınan belgenin sadece ilgili
                kurum/kuruluş tarafından doğrulanması i&ccedil;in kullanılır.
              </span>
            </em>
          </p>
          <p>
            <em>
              <span>
                2. &nbsp; &nbsp;Barkodlu Belge Doğrulama hizmeti ile verilen
                belgedeki bilgiler; &uuml;&ccedil;&uuml;nc&uuml; şahıs, kurum ve
                kuruluşlarca veriliş amacı dışında kullanılamaz.
              </span>
            </em>
          </p>
          <p>
            <em>
              <span>
                3. &nbsp; &nbsp;Barkodlu Belge Doğrulama hizmeti ile verilen
                belgenin doğrulama sonucuna erişim izni bulunmayanların bu
                işlemi (doğrulama) ger&ccedil;ekleştirmesi yasal sorumluluk
                doğurur.
              </span>
            </em>
          </p>
        </Modal>
      </Spin>
      <Modal
        visible={visible}
        size="large"
        onClose={closeModal}
        title={'Doğrulanan Belge'}
      >
        <iframe
          style={{
            width: '100%',
            minHeight: 'calc(80vh - 80px)',
          }}
          src={`${file}`}
        ></iframe>
      </Modal>
    </AuthLayout>
  );
}
