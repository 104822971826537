import React, { useState } from 'react';
import { styled, Menu, Button, Icon } from 'ykb-ui';
import MegaMenuThumbItems from './MegaMenuThumbItems';
import MegaMenuListItems from './MegaMenuListItems';
import ResizeObserver from 'rc-resize-observer';
import MegamenuDescription from './MegamenuDescription';

const Item = Menu.Item;
const SubMenu = Menu.SubMenu;

const MenuButton = styled(Button)`
  background: transparent !important;
  // padding: 20px 12px;
  padding: 20px 15px;
  height: auto;
  border: 0px !important;
  display: flex;
  width: 100%;
  height: 60px;
  margin-top: 1px;
  > .text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0px;
  }
`;

const MenuItem = styled(Item)`
  background: transparent !important;
  padding: 0px !important;
  border: 0px !important;
  display: flex;
  &.ykb-menu-item-active {
    ${MenuButton} {
      text-decoration: underline !important;
      @media (max-width: ${props => props.theme.responsiveMdMax}) {
        text-decoration: none !important;
      }
    }
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    display: block;
  }
`;

const SubMenuItem = styled(SubMenu)`
  background: transparent !important;
  padding: 0px;
  border: 0px !important;
  position: relative;
  .ykb-menu-submenu-title {
    border: 0px !important;
  }

  &:after {
    content: '';
    opacity: 0;
    transition-delay: 0.3s;
  }
  &.ykb-menu-submenu-active {
    ${MenuButton} {
      text-decoration: underline !important;
      @media (max-width: ${props => props.theme.responsiveMdMax}) {
        text-decoration: none !important;
      }
    }
    &:after {
      content: '';
      position: absolute;
      width: 0px;
      height: 0px;
      z-index: -1;
      bottom: -1px;
      left: 45%;
      opacity: 1;
      border-color: transparent transparent
        ${props => props.theme.color.primary8} transparent;
      border-width: 10px 8px 10px 8px;
      border-style: solid;
      transform: translate(-50%);
      @media (max-width: ${props => props.theme.responsiveMdMax}) {
        background: ${props => props.theme.color.primary8};
        width: 20px;
        height: 14px;
        border: 1px solid white;
        transform: rotate(45deg) translatey(50%);
        bottom: -5px;
        left: 45%;
      }
      @media (max-width: ${props => props.theme.responsiveXsMax}) {
        content: none;
      }
    }
  }
`;

export default function NavigationItems({
  data,
  handleClick,
  openKeys,
  setOpenKeys,
}) {
  const [mode, setMode] = useState();

  const handleNavClick = (e, item) => {
    setOpenKeys([]);
    handleClick(e, item);
  };
  const onOpenChange = (e, item) => {
    setOpenKeys(e);
  };

  const listMenuExists = item => {
    return item.listMenu && item.listMenu.length > 0;
  };

  function renderItems(data) {
    return data.map(
      item =>
        !!item.title &&
        (item.children ? (
          <SubMenuItem
            popupClassName="megamenu"
            key={item.id}
            title={<MenuButton>{item.title}</MenuButton>}
          >
            <MenuItem>
              {item.children.thumbnailMenu &&
                item.children.thumbnailMenu.length > 0 && (
                  <MegaMenuThumbItems
                    onlyChild={!listMenuExists(item.children)}
                    data={item.children.thumbnailMenu}
                    handleNavClick={handleNavClick}
                  >
                    {MegamenuDescription(item)}
                  </MegaMenuThumbItems>
                )}

              {listMenuExists(item.children) && (
                <MegaMenuListItems
                  data={item.children.listMenu}
                  handleNavClick={handleNavClick}
                />
              )}
            </MenuItem>
          </SubMenuItem>
        ) : (
          <MenuItem key={item.id}>
            <MenuButton onClick={e => handleNavClick(e, item)}>
              {item.title}
              <Icon
                name="arrow-right"
                color="white"
                className="visible-xs"
                size={24}
              />
            </MenuButton>
          </MenuItem>
        ))
    );
  }

  const resize = () => {
    setMode(window.innerWidth < 768 ? 'inline' : 'horizontal');
  };
  return (
    <React.Fragment>
      <ResizeObserver onResize={resize}>
        <Menu
          prefixCls="ykb-menu"
          subMenuCloseDelay={0.2}
          onOpenChange={onOpenChange}
          openKeys={openKeys}
          getPopupContainer={() => document.getElementById('popup-container')}
          mode={mode}
        >
          {renderItems(data)}
        </Menu>
      </ResizeObserver>
    </React.Fragment>
  );
}
