import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  MemoryRouter,
  useHistory,
} from 'react-router-dom';

import SurveySuccess from './Success';
import Survey from './Form';
import SurveyWarning from './Warning';

export default function SurveyPages() {
  let { path } = useRouteMatch();
  const history = useHistory();
  const id = history.location.pathname.replace('/ykb-esv-anket/', '');

  return (
    <Switch>
      <Route path={path}>
        <MemoryRouter>
          <Switch>
            <Route path={`/islem-basarili`}>
              <SurveySuccess browserHistory={history} id={id} />
            </Route>
            <Route path={`/uyari`}>
              <SurveyWarning browserHistory={history} id={id} />
            </Route>
            <Route path={'/'}>
              <Survey browserHistory={history} id={id} />
            </Route>
          </Switch>
        </MemoryRouter>
      </Route>
    </Switch>
  );
}
