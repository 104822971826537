import React, { useEffect, useState } from 'react';
import { Select } from 'ykb-ui';

export default function ESVInfoTypeSelect({ name, validation, data }) {
  const [esvInfoTypes, setESVInfoTypes] = useState([]);

  useEffect(() => {
    setESVInfoTypes(convertData(data));
  }, [data]);

  function convertData(data) {
    let response = [];
    let mainGroups = getMainGroups(data);

    for (let i of mainGroups) {
      const element = i;

      let items = data
        .filter(function(el) {
          return el.mainGroupName === element;
        })
        .map(item => {
          return {
            label: item.typeName,
            value: item.id,
          };
        });
      response.push({ label: element, data: items });
    }

    return response;
  }

  function getMainGroups(data) {
    let lookup = {};
    let result = [];

    for (const item of data) {
      let name = item.mainGroupName;

      if (!(name in lookup)) {
        lookup[name] = 1;
        result.push(name);
      }
    }
    return result;
  }

  return (
    <Select
      name={name}
      validation={validation}
      showSearch
      data={esvInfoTypes}
    />
  );
}
