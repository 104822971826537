import React, { useState, useEffect, useRef } from 'react';
import ESVInfoTypeSelect from './ESVInfoTypeSelect';
import {
  Modal,
  Button,
  Form,
  Textarea,
  Notification,
  InfoBox,
  Message,
  Select,
  Spin,
  Checkbox,
} from 'ykb-ui';

import callApi from '../../../utils/callApi';

export default function ESVInfoModal({ visible, handleVisible }) {
  const [esvInfoTypes, setESVInfoTypes] = useState([]);
  const [categoryType, setCategoryType] = useState(0);
  const [selectedESVInfoType, setSelectedESVInfoType] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formShow, setFormShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const categories = [
    { label: 'Önerim Var', value: '1' },
    { label: 'Teşekkür Etmek İstiyorum', value: '2' },
    { label: 'Şikayetim Var', value: '3' },
    { label: 'Sorum Var', value: '4' },
  ];

  useEffect(() => {
    getTypList();
  }, []);

  async function getTypList() {
    try {
      setLoading(true);
      const response = await callApi({
        method: 'GET',
        endpoint: '/ESVSor/GetTypeList',
      });
      setESVInfoTypes(response);
    } finally {
      setLoading(false);
    }
  }

  function handleModalClose() {
    handleVisible(false);
  }

  function onValuesChange(changedValues) {
    if (changedValues.id) {
      let selected = esvInfoTypes.filter(item => {
        return item.id === changedValues.id;
      });
      if (selected != null) {
        setSelectedESVInfoType(selected[0]);
      }
    }
    if (changedValues.categoryType) {
      setCategoryType(changedValues.categoryType);
    }
  }

  function handleFormSubmit(e, error, values) {
    e.preventDefault();
    if (!error) {
      Message.prompt({
        title: 'Kaydı Kaydet',
        content: `Kaydınız ilgili birimine gönderilecektir.\nDevam etmek istiyor musunuz?`,
        onClose: () => {
          console.log('onClose');
        },
        onOk: () => {
          postESVInfoRequest(values);
        },
        onCancel: () => {
          console.log('onCancel');
        },
      });
    }
  }

  async function postESVInfoRequest(values) {
    try {
      setLoading(true);
      setButtonDisabled(true);
      await callApi({
        endpoint: 'EsvSor/Insert',
        method: 'POST',
        body: values,
      });
      Notification.success(`Kaydınız ilgili birimine gönderilmiştir.`);
      handleModalClose();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      setButtonDisabled(false);
    }
  }

  function askEsv(rule, value, callback) {
    if (value.length > 500) {
      callback(new Error('Açıklama en fazla 500 karakter olmalıdır.'));
    } else {
      callback();
    }
  }

  return (
    <Spin spinning={loading}>
      <Modal
        visible={visible}
        title="ESV'ye Sor - Kayıt Aç"
        onClose={handleModalClose}
      >
        {formShow ? (
          <Form
            ref={formRef}
            onSubmit={handleFormSubmit}
            onValuesChange={onValuesChange}
          >
            <Form.Item colSpan={{ span: 24 }} label="Kayıt Kategorisi">
              <Select
                name="categoryType"
                validation={[{ required: true }]}
                data={categories}
              />
            </Form.Item>
            {categoryType != 2 && (
              <Form.Item colSpan={{ span: 24 }} label="Konu Başlığı">
                <ESVInfoTypeSelect
                  name="id"
                  data={esvInfoTypes}
                  validation={[
                    {
                      required: categoryType != 2,
                    },
                  ]}
                />
                {selectedESVInfoType && (
                  <InfoBox>{selectedESVInfoType.description}</InfoBox>
                )}
              </Form.Item>
            )}

            <Form.Item colSpan={{ span: 24 }} label="Konu Açıklaması">
              <Textarea
                style={{ width: '100%', height: '100%' }}
                name="description"
                validation={[{ required: true }, { validator: askEsv }]}
              />
            </Form.Item>

            <Form.Item colSpan={{ span: 24 }}>
              <Button
                className="right-floated"
                htmlType="submit"
                type="primary"
                disabled={buttonDisabled}
              >
                KAYDI OLUŞTUR
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Checkbox onChange={() => setFormShow(true)}>
            <span>
              Kaydınızı açmadan önce,{' '}
              <a href={process.env.PUBLIC_URL + '/sss'} target="_blank">
                Sıkça Sorulan Sorular
              </a>{' '}
              başlığına baktınız mı ?
            </span>
          </Checkbox>
        )}
      </Modal>
    </Spin>
  );
}
