import React, { Suspense, lazy, useEffect, Fragment, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { Spin } from 'ykb-ui';
import { useAuthentication } from '../context/Authentication';
import {
  NotificationProvider,
  useNotification,
} from '../context/NotificationContext';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Main from '../components/Main';
import IdleTimer from '../components/Page/IdleTimer';
import DocumentVerification from './DocumentVerification';
import Survey from './Survey';
import BirthdayAnimation from '../components/Animations/Birthday';
import WelcomeAnimation from '../components/Animations/Welcome';

import SSS from './SSS';

const Home = lazy(() => import('./Home'));
const HealthOperations = lazy(() => import('./HealthOperations'));
const RetirementOperations = lazy(() => import('./RetirementOperations'));
const FoundationOperations = lazy(() => import('./FoundationOperations'));
const LoginOperations = lazy(() => import('./LoginOperations'));
const NotFound = lazy(() => import('./NotFound'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const MyProfile = lazy(() => import('./MyProfile'));
const ChangePassword = lazy(() => import('./ChangePassword'));
const Notifications = lazy(() => import('./Notifications'));
const UploadFile = lazy(() => import('./UploadFile'));
const Kvkk = lazy(() => import('./KvkkApprove'));
const Ask = lazy(() => import('./Ask'));
const MobileRedirect = lazy(() => import('./MobileRedirect'));
const MobilAppCard = lazy(() => import('../components/Header/MobilAppCard'));

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.

function PrivateRoute({ children, ...rest }) {
  const { user, loginError, checkAuth } = useAuthentication();
  const location = useLocation();
  const [birthdayClosed, setBirthdayClosed] = useState(false);
  const [userBirthday] = useState(user?.isBirthdayMessage);
  const [welcomeClosed, setWelcomeClosed] = useState(false);
  const [userWelcome] = useState(user?.isFirstLoginMessage);

  useEffect(() => {
    if (location.pathname && !location.pathname.startsWith('/login')) {
      checkAuth();
    }
  }, []);

  if (!user && !loginError) {
    return <Spin spinning={true} />;
  }
  return (
    <Fragment>
      {userBirthday && !birthdayClosed && (
        <BirthdayAnimation setBirthdayClosed={setBirthdayClosed} />
      )}

      {userWelcome && !welcomeClosed && (
        <WelcomeAnimation setWelcomeClosed={setWelcomeClosed} />
      )}

      {user ? (
        <NotificationProvider>
          <NotificationConsumer>{children}</NotificationConsumer>
        </NotificationProvider>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )}
    </Fragment>
  );
}

function NotificationConsumer({ children }) {
  const { fetchNotifications } = useNotification();
  useEffect(() => {
    fetchNotifications();
  }, []);

  return children;
}

export default function Routes() {
  const { user } = useAuthentication();
  let url = '/';
  if (process.env.REACT_APP_PUBLIC_SUBFOLDER) {
    url = process.env.REACT_APP_PUBLIC_SUBFOLDER;
  }

  return (
    <Router basename={`${url}`}>
      <Suspense fallback={<Spin spinning={true} />}>
        <Switch>
          <Route path="/esv-mobil-yukle">
            <MobileRedirect />
          </Route>
          <Route exact path="/login">
            <MobilAppCard />
            <LoginOperations />
          </Route>
          <Route exact path="/belge-dogrulama/:id">
            <DocumentVerification />
          </Route>
          <Route exact path="/belge-dogrulama">
            <DocumentVerification />
          </Route>
          <Route path="/ykb-esv-anket/:id">
            <Survey />
          </Route>

          {/* <Route path="/ykb-esv-anket">
            <MemoryRouter>
              <Switch>
                <Route exact path={'/:id'}>
                  <Survey />
                </Route>
                <Route path={`/islem-basarili`}>
                  <SurveySuccess />
                </Route>
              </Switch>
            </MemoryRouter>
          </Route> */}

          <PrivateRoute>
            <Header />
            <Main>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/saglik-islemleri">
                  <HealthOperations />
                </Route>
                <Route path="/emeklilik-islemleri">
                  <RetirementOperations />
                </Route>
                <Route path="/vakif-islemleri">
                  <FoundationOperations />
                </Route>
                <Route path="/bildirimler">
                  <Notifications />
                </Route>
                <Route path="/profil">
                  <MyProfile />
                </Route>
                <Route path="/sifre-degistir">
                  <ChangePassword />
                </Route>
                <Route path="/belge-yukleme">
                  <UploadFile />
                </Route>
                <Route path="/kvkk-onaylarim">
                  <Kvkk />
                </Route>
                <Route exact path="/sss">
                  <SSS />
                </Route>
                <Route exact path="/esvye-sor">
                  <Ask />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Main>
            <Footer />
            {user && user.privacyPolicy && user.privacyPolicy.length > 0 && (
              <PrivacyPolicy />
            )}
            <IdleTimer />
          </PrivateRoute>
        </Switch>
      </Suspense>
    </Router>
  );
}
