import React from 'react';
import Page from '../Page';
import { styled, Typography, Icon, InfoBox } from 'ykb-ui';

const GroupContent = styled(Page.GroupContent)`
  padding: 20px;
  text-align: center;
`;

const IconWrapper = styled.div`
  background: ${props => props.theme.color.success6};
  padding: 5px;
  border-radius: 50%;
  display: inline-block;
  margin: 20px 20px 30px;
`;

const Title = styled(Typography.Title)`
  & {
    color: ${props => props.theme.color.neutral11};
    font-weight: 500;
    margin-bottom: 28px;
  }
`;

const Paragraph = styled(Typography.Paragraph)`
  & {
    color: ${props => props.theme.color.neutral11};
    font-weight: 400;
    font-size: 16px;
    margin-top: 0px;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
`;

const Box = styled(InfoBox)`
  margin: 0 auto;
  max-width: 590px;
  margin-bottom: 30px;
  background-color: ${props => props.theme.color.secondary1};
  border: 0px;
  ${Paragraph} {
    margin-bottom: 0px;
    text-align: left;
    margin-left: 0px;
  }
  ${Icon} {
    color: ${props => props.theme.color.primary6};
  }
  div {
    border: 0px !important;
  }
`;

export default function WarningPage() {
  return (
    <Page title="">
      <Page.Group>
        <GroupContent>
          <IconWrapper>
            <Icon name="info-circle" size="60" color="#fff" />
          </IconWrapper>
          <Title level={4}>Anket gönderim süresi dolmuştur.</Title>
          <Paragraph>Teşekkürler.</Paragraph>
        </GroupContent>
      </Page.Group>
    </Page>
  );
}
