import React from 'react';
import { styled, Typography } from 'ykb-ui';

const PageContent = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  margin: 10px 0;
  border-radius: 4px;
`;

export default function Content({ children, title, ...rest }) {
  return (
    <React.Fragment>
      {title && <Typography.Title level={2}>{title}</Typography.Title>}
      <PageContent {...rest}>{children}</PageContent>
    </React.Fragment>
  );
}
