import React, { useState, useEffect } from 'react';

export default function CountDown({ from, onComplete }) {
  let [timeLeft, setTimeLeft] = useState(from);

  useEffect(() => {
    // exit early when we reach 0
    if (timeLeft <= 0) {
      if (onComplete) onComplete();
      return;
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearTimeout(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  // Set up the interval.
  useEffect(() => {
    setTimeLeft(from);
  }, [from]);

  return <div className="countdown-timer">{timeLeft ? timeLeft : 0} sn</div>;
}
