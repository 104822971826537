import React from 'react';
import { styled, Container, Divider as YkbDivider } from 'ykb-ui';
import Bottom from './Bottom';
import Middle from './Middle';
import Top from './Top';

const ContainerStyled = styled(Container)``;

const Divider = styled(YkbDivider)`
  && {
    background: white;
    opacity: 0.5;
    margin: 0px;
  }
`;

const FooterWrapper = styled.footer`
  background: ${props => props.theme.color.primary6};
  color: #fff;
  display: flex;
  position: relative;
`;

export default function Footer() {
  return (
    <FooterWrapper>
      <ContainerStyled>
        <Top />
        <Divider />
        <Middle />
        <Divider />
        <Bottom />
      </ContainerStyled>
    </FooterWrapper>
  );
}
