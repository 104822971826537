import React from 'react';
import { styled, Row, Col } from 'ykb-ui';

const ContainerRow = styled.div`
  background-color: white;
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  display: flex;
`;

const ContentColumn = styled.div`
  background-color: white;
  display: block;
  width: 50%;
  border-radius: 10px 0 0 10px;
  @media (max-width: 767px) {
    width: 100%;
    border-radius: 10px;
  }
`;

const ImageColumn = styled(ContentColumn)`
  background: white url(${props => props.image}) no-repeat scroll 50% 50%;
  background-size: cover;
  border-radius: 0 10px 10px 0;
  @media (max-width: 767px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  min-height: 400px;
  margin-top: 60px;
  padding-bottom: 20px;
  @media (max-width: 767px) {
    margin-top: 40px;
  }
`;

export default function SplitedContent({ children, image }) {
  return (
    <React.Fragment>
      <ContainerRow>
        <ContentColumn>
          <ContentContainer>
            <Row>
              <Col
                xsOffset={1}
                xs={22}
                smOffset={4}
                sm={16}
                lgOffset={4}
                lg={16}
              >
                {children}
              </Col>
            </Row>
          </ContentContainer>
        </ContentColumn>
        <ImageColumn image={image} />
      </ContainerRow>
    </React.Fragment>
  );
}
