import React, { useState, useEffect } from 'react';
import {
  Modal as YkbModal,
  Button,
  Form,
  TextInput,
  Spin,
  Card,
  Row,
  Col,
  Icon,
  styled,
  Typography,
  PhoneInput,
  Notification,
} from 'ykb-ui';
import image from '../../assets/form-images/phone.png';
import qrImage from '../../assets/form-images/qr.png';
import callApi from '../../utils/callApi';
import iosImage from '../../assets/apple-store-button.png';
import androidImage from '../../assets/android-store-button.png';
import { isMobile } from 'react-device-detect';

const Modal = styled(YkbModal)`
  > div > div {
    padding: 0px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: -105px;
    max-height: max-content;
  }

  > div > button {
    z-index: 1;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  left: 0px;
  right: 66%;
  top: 0px;
  bottom: 0px;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9eaeb;
`;

const Image = styled.img`
  margin: 0 auto;
  max-width: 90%;
  position: relative;
  left: 5px;
`;

const ContentWrapper = styled.div`
  padding: 10px;
`;

const QrWrapper = styled.div`
  background: ${props => props.theme.color.primary6};
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
`;

const AppDownloadButton = styled.a`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  overflow: hidden;
  text-decoration: none;
`;

const SmsTitle = styled(Typography.Title)`
  color: ${props => props.theme.color.primary6};
`;

export default function ESVInfoModal({ visible, handleVisible }) {
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [loading, setLoading] = useState(false);

  const [refreshToken, setRefreshToken] = useState(0);

  useEffect(() => {}, []);

  function isMobilee() {
    debugger;
    const md = isMobile;
    return md;
  }

  const Title = styled(Typography.Title)`
    color: ${props => props.theme.color.primary6} !important;
    font-weight: 500 !important;
    margin-top: 10px !important;
    margin-bottom: 15px !important;
    line-height: 1 !important;
    @media (max-width: 767px) {
      line-height: 26px !important;
      font-size: 20px !important;
    }
  `;

  function refreshCaptcha() {
    setRefreshToken(refreshToken + 1);
  }
  function submitForm(e, error, values) {
    e.preventDefault();
    if (!error) {
      postSms(values);
    }
  }

  function handleModalClose() {
    handleVisible(false);
  }

  async function postSms(values) {
    try {
      setButtonDisabled(true);
      setLoading(true);
      refreshCaptcha();
      const response = await callApi({
        endpoint: 'MobilApp/SendSmsDownload',
        method: 'POST',
        body: values,
      });
      if (response) {
        Notification.success(`SMS gönderildi.`);
      } else {
        Notification.error('SMS gönderilirken bir hata oluştu', 8);
      }

      handleModalClose();
    } catch (e) {
      Notification.error(`SMS gönderilirken bir hata oluştu.`, 8);
    } finally {
      setLoading(false);
      setButtonDisabled(false);
    }
  }

  return (
    <Spin spinning={loading}>
      <Modal onClose={handleModalClose} visible={true} title="" size="xlarge">
        <Row style={{ position: 'relative' }}>
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={8}
            className="hidden-xs"
            style={{ position: 'static' }}
          >
            <ImageWrapper>
              <Image src={image} alt="Facebook Profilimiz" />
            </ImageWrapper>
          </Col>

          <Col xs={24} sm={16} md={16} lg={16}>
            <ContentWrapper>
              <Title>ESV Mobil Cepte</Title>
              <Card>
                <p>
                  <b>Yapı Kredi Emekli Sandığı Üyesi İseniz;</b>
                </p>
                <p>
                  ESV Mobil'i hemen indirip Tek Şifre'niz ile giriş yaparak ya
                  da yeni bir şifre oluşturarak anında kullanmaya
                  başlayabilirsiniz.
                </p>

                <p>
                  <b>Yapı Kredi Emekli Sandığı Üyesi Değilseniz;</b>
                </p>
                <p>
                  ESV Mobil'i indirip Üye Olun adımından üyelik işlemlerinizi
                  tamamlayarak kullanmaya başlayabilirsiniz.
                </p>
              </Card>
              <QrWrapper>
                {isMobilee() ? (
                  <div>
                    <p>ESV Mobil uygulamasını indirin!</p>
                    <div>
                      <AppDownloadButton
                        href="https://apps.apple.com/tr/app/esv-mobil/id1609917748"
                        target="_blank"
                      >
                        <div>
                          <img src={iosImage} alt="App Store" />
                        </div>
                      </AppDownloadButton>
                      <AppDownloadButton
                        href="https://play.google.com/store/apps/details?id=com.ykb.esv"
                        target="_blank"
                      >
                        <div>
                          <img src={androidImage} alt="Google Play" />
                        </div>

                        {/* <div>
                          <img src="http://via.placeholder.com/30x30" />
                        </div>
                        <div>
                          <div>Google Play'den</div>
                          <div>Alın</div>
                        </div> */}
                      </AppDownloadButton>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Row>
                      <Col sm={6}>
                        <img src={qrImage} alt="Facebook Profilimiz" />
                      </Col>
                      <Col sm={10}>
                        <p>
                          <b>QR Kod İle İndir</b>
                        </p>
                        <p>
                          QR kodu okutarak cihazınıza uygun ESV Mobil
                          uygulamasını indirin!
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}
              </QrWrapper>
              <div>
                <SmsTitle level={4}> Sms Linki İle İndir</SmsTitle>
                <Form
                  // ref={formRef}
                  colSpan={{ xs: 24, md: 24 }}
                  onSubmit={submitForm}
                >
                  <Form.Item>
                    <Row>
                      <Col
                        sm={24}
                        md={24}
                        lg={10}
                        style={{
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <Form.Item label="Cep Telefonu Numarası">
                          <PhoneInput
                            name="phone"
                            validation={[{ required: true }]}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        sm={12}
                        md={12}
                        lg={7}
                        style={{
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <Form.Item label={'Güvenlik Kodu'}>
                          <TextInput
                            name="captchaId"
                            validation={[{ required: true }]}
                            validationOptions={{
                              validateTrigger: ['onSubmit', 'onBlur'],
                            }}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        sm={12}
                        md={12}
                        lg={7}
                        style={{
                          paddingLeft: '0px',
                          paddingRight: '0px',
                          overflow: 'hidden',
                        }}
                      >
                        <Form.Item label={''}>
                          <img
                            alt="captcha"
                            src={`${process.env.REACT_APP_API}/Auth/GetCaptchaImage?${refreshToken}`}
                            style={{
                              height: '40px',
                              float: 'left',
                            }}
                          />
                          <Button
                            onClick={refreshCaptcha}
                            className="rightFloated"
                            style={{
                              float: 'left',
                              margin: '0px 0px 0px 10px ',
                            }}
                          >
                            <Icon name="refresh" />
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col md={24}>
                        <div>
                          <Form.Item>
                            *SMS Gönderimi Ücretsizdir.
                            <Button
                              disabled={buttonDisabled}
                              type="primary"
                              htmlType="submit"
                              className="rightFloated"
                              style={{ marginTop: '10px', float: 'right' }}
                            >
                              SMS Gönder
                            </Button>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </div>
            </ContentWrapper>
          </Col>
        </Row>
      </Modal>
    </Spin>
  );
}
