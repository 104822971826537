import React from 'react';
import callApi from '../utils/callApi';
const MenuContext = React.createContext();

const flatData = [];

const initialState = [
  {
    initialData: [],
    flatData: [],
    filteredData: [],
    fetching: false,
    fetchError: false,
  },
];

// Reducer
function menuReducer(state, action) {
  switch (action.type) {
    case 'FETCH': {
      return { ...state, fetching: true };
    }
    case 'FETCH_SUCCESS': {
      return {
        ...state,
        fetching: false,
        initialData: action.payload,
        filteredData: action.payload,
      };
    }
    case 'FETCH_FAIL': {
      return { ...state, fetching: false, fetchError: action.payload.error };
    }
    case 'FLATTEN': {
      return { ...state, fetching: false, flatData: action.payload };
    }
    case 'FILTER': {
      const query = action.payload;

      if (query === '') {
        return { ...state, fetching: false, filteredData: state.initialData };
      }

      return {
        ...state,
        fetching: false,
        filteredData: state.flatData.filter(item => {
          if (
            toTurkishLowerCase(item.title).includes(toTurkishLowerCase(query))
          ) {
            return item;
          }
        }),
      };
    }
    default: {
      return state;
    }
  }
}

// Hook
function useMenu() {
  const context = React.useContext(MenuContext);

  if (!context) {
    throw new Error(`useMenu hook must be used within a MenuProvider`);
  }

  const [state, dispatch] = context;

  // Actions
  function fetchStart(data) {
    dispatch({ type: 'FETCH', payload: data });
  }

  function fetchSuccess(data) {
    dispatch({ type: 'FETCH_SUCCESS', payload: data });
  }

  function fetchFail(data) {
    dispatch({ type: 'FETCH_FAIL', payload: data });
  }

  function flatten(data) {
    dispatch({ type: 'FLATTEN', payload: data });
  }

  function search(query) {
    dispatch({ type: 'FILTER', payload: query });
  }

  // Action Creators
  async function fetchMenu() {
    fetchStart();
    try {
      const response = await await callApi({
        endpoint: 'Menu/GetMenuWeb',
      });

      fetchSuccess(response);
      flatten(flattenMenuData(response));
    } catch (ex) {
      fetchFail(ex);
    }
  }
  return {
    menu: state.filteredData,
    flatData: state.flatData,
    fetching: state.fetching,
    fetchMenu: fetchMenu,
    search: search,
  };
}

// Provider
function MenuProvider(props) {
  const [state, dispatch] = React.useReducer(menuReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <MenuContext.Provider value={value} {...props} />;
}

// Helpers
function toTurkishLowerCase(str) {
  const uppperIRegExp1 = /I/g;
  const upperIRegExp2 = /İ/g;

  if (str)
    return str
      .replace(uppperIRegExp1, 'ı')
      .replace(upperIRegExp2, 'i')
      .toLowerCase();
  return str;
}

function flattenMenuData(data) {
  data.forEach(item => {
    if (item.children) {
      flattenMenuData(item.children);
    } else {
      flatData.push(item);
    }
  });
  return flatData;
}

export { MenuProvider, useMenu };
