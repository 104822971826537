import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled, Container } from 'ykb-ui';
import Profile from './Profile';
import Navigation from '../Navigation';
import Notification from './Notification';
import { ReactComponent as EsvLogo } from './esv-logo-beyaz.svg';
import ToggleButton from './ToggleButton';
import MobilAppCard from './MobilAppCard';

const StyledContainer = styled(Container)``;
const HeaderWrapper = styled.header`
  background: ${props => props.theme.color.primary6};
  height: 60px;
  max-height: 60px;
  display: flex;
  z-index: 11;
  + section {
    padding-bottom: 40px;
  }
  ${StyledContainer} {
    @media (max-width: ${props => props.theme.responsiveMdMax}) {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;
const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled(EsvLogo)`
  width: 170px;
  height: 50px;
  margin-right: 40px;
  vertical-align: middle;
  cursor: pointer;
  @media (max-width: ${props => props.theme.responsiveMdMax}) {
    height: 45px;
    margin-right: 20px;
  }
`;

const LogoWrapper = styled.div`
  @media (max-width: ${props => props.theme.responsiveMdMax}) {
    flex: 1;
  }
`;

export default function Header() {
  const [navVisible, setNavVisible] = useState(false);

  const history = useHistory();
  function redirectToHome(e) {
    e.preventDefault();
    history.push('/');
  }
  const toggleNav = () => {
    setNavVisible(!navVisible);
  };

  return (
    <>
      <MobilAppCard />
      <HeaderWrapper>
        <StyledContainer>
          <HeaderContent id="header-nav">
            <ToggleButton navVisible={navVisible} toggleNav={toggleNav} />
            <LogoWrapper>
              <Logo onClick={redirectToHome} />
            </LogoWrapper>

            <Navigation visible={navVisible} toggleNav={toggleNav} />
            <Notification />
            <Profile />
          </HeaderContent>
        </StyledContainer>
      </HeaderWrapper>
    </>
  );
}
