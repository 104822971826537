import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled, css, Spin } from 'ykb-ui';

import { useMenu } from '../../context/MenuContext';
import NavigationItems from './NavigationItems';
import { default as MenuPopupContainer } from './PopupContainer';
import { useAuthentication } from '../../context/Authentication';

const Nav = styled.nav`
  display: flex;
  width: 100%;
  .ykb-menu {
    margin: 0px;
    background: transparent;
    border: 0px;
    width: 100%;
    box-shadow: none;
    .ykb-menu-item,
    .ykb-menu-submenu-title {
      background: transparent !important;
      padding: 0px;
      @media (max-width: ${props => props.theme.responsiveXsMax}) {
        padding: 0px !important;
        flex-direction: column;
        display: flex;
        margin-left: 0px;
        max-width: unset;
        justify-content: center;
      }
    }
  }

  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    position: fixed;
    width: 300px;
    left: -300px;
    background: ${props => props.theme.color.primary8};
    top: 60px;
    bottom: 0px;
    transition: all 0.3s ease-in;
    overflow-x: hidden;
    ${props =>
      props.navVisible &&
      css`
        transform: translate(300px);
      `}
  }

  @media (min-width: ${props =>
      props.theme.responsiveSmMin}) and (max-width: ${props =>
      props.theme.responsiveMdMax}) {
    position: fixed;
    height: 60px;
    top: 0px;
    left: 0px;
    right: 0px;
    background: ${props => props.theme.color.primary8};
    transition: all 0.1s ease-in;
    z-index: -1;
    transform: translatey(0);
    opacity: 0;
    ${props =>
      props.navVisible &&
      css`
        opacity: 1;
        transform: translatey(60px);
        border-bottom: 1px solid white;
      `}
  }
`;

export default function Navigation({ visible, toggleNav }) {
  const { user } = useAuthentication();
  const { menu = [], fetchMenu, fetching } = useMenu();
  const history = useHistory();

  useEffect(() => {
    fetchMenu();
  }, [user]);

  // preload menu images for better ux
  useEffect(() => {
    if (menu?.length > 0) {
      for (const item of menu) {
        if (
          item.children &&
          item.children.thumbnailMenu &&
          item.children.thumbnailMenu.length > 0
        ) {
          for (const menuItem of item.children.thumbnailMenu) {
            pushImage(menuItem);
          }
        }
      }
    }
    function pushImage(menuItem) {
      if (menuItem.thumbnail) {
        const image = new Image();
        image.src = menuItem.thumbnail;
      }
      if (menuItem.icon) {
        const image = new Image();
        image.src = menuItem.icon;
      }
    }
  }, [menu]);

  const [openKeys, setOpenKeys] = useState([]);
  function handleClick(e, item) {
    e.preventDefault();
    history.push(item.route);
    toggleNav();
  }

  return (
    <React.Fragment>
      <Nav id="header-navigation" navVisible={visible}>
        <Spin spinning={fetching} text="">
          <NavigationItems
            data={menu}
            handleClick={handleClick}
            openKeys={openKeys}
            setOpenKeys={setOpenKeys}
          />
        </Spin>
      </Nav>

      <MenuPopupContainer openKeys={openKeys} navVisible={visible} />
    </React.Fragment>
  );
}
