import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { ReactComponent as EmptyIcon } from '../../assets/nodata-small.svg';
import { ReactComponent as GiftIcon } from '../../assets/birtday-small.svg';
import { ReactComponent as SalaryIcon } from '../../assets/maas-small.svg';
import { Icon, Button, styled, css } from 'ykb-ui';
import moment from 'moment';

const List = styled.div`
  max-height: 500px;
  color: ${props => props.theme.color.default10};
  padding-right: 20px;
`;

const ListItem = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  &:hover {
    background-color: ${props => props.theme.color.default2};
  }
  ${props =>
    props.$active &&
    css`
      background-color: ${props => props.theme.color.default1};
    `}
`;

const NoWrap = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 19px;
`;

const Circle = styled.div`
  background-color: ${props => props.theme.color.default1};
  border-radius: 50%;
  width: 160px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-right: 17px;
`;

const AngleIcon = styled(Icon)`
  color: ${props => props.theme.color.primary6};
  font-size: 24px;
  align-self: center;
`;

const EmptyWrapper = styled.div`
  img {
    max-width: 100%;
    height: auto;
  }
  p {
    font-size: 14px;
    color: ${props => props.theme.color.default10};
    margin: 20px 0px 0px 0px;
    text-align: center;
  }
`;

const NotificationDate = styled.div`
  color: ${props => props.theme.color.default6};
  font-size: 12px;
`;
const GiftWrapper = styled.div`
  margin-right: 20px;
`;
const SalaryWrapper = styled.div`
  margin-right: 0px;
  margin-left: auto;
`;
const Detail = styled.div`
  font-size: 14px;
  line-height: 19px;
  flex: 1 auto;
`;

export function EmptyContent({ text }) {
  return (
    <EmptyWrapper>
      <Circle>
        <EmptyIcon />
      </Circle>
      <p>{text}</p>
    </EmptyWrapper>
  );
}

const NotificationListItem = ({
  data,
  readNotifications,
  setBirthdayClosed,
  renderDate,
}) => {
  const handleClick = () => {
    if (data.yonlendirme_Url_Web) {
      readNotifications([data.id]);
      setTimeout(() => {
        let target = '_self';
        if (data.baslik === 'Anket Bilgilendirme') target = '_blank';
        window.open(data.yonlendirme_Url_Web, target);
      }, 100);
    }
    if (data.aciklama.includes('Doğum günün kutlu olsun')) {
      readNotifications([data.id]);
      setBirthdayClosed(false);
    }
  };

  const handleButtonClick = event => {
    event.stopPropagation();
    readNotifications([data.id]);
    setTimeout(() => {
      let target = '_self';
      if (data.baslik === 'Anket Bilgilendirme') target = '_blank';
      window.open(data.yonlendirme_Url_Web, target);
    }, 100);
  };

  return (
    <ListItem $active={data.durum} onClick={handleClick}>
      <NoWrap>
        {data.baslik === 'Tebrik ve Kutlamalar' && (
          <GiftWrapper>
            <GiftIcon />
          </GiftWrapper>
        )}
        <Detail>
          {data.aciklama}
          <NotificationDate>{renderDate(data)}</NotificationDate>
        </Detail>

        {data.baslik === 'Maaş' && (
          <SalaryWrapper>
            <SalaryIcon />
          </SalaryWrapper>
        )}
        {data.yonlendirme_Url_Web && (
          <Button type="ghost" onClick={handleButtonClick}>
            <AngleIcon name="angle-right" />
          </Button>
        )}
      </NoWrap>
    </ListItem>
  );
};

export default function NotificationList({
  notifications,
  readNotifications,
  setBirthdayClosed,
}) {
  function renderDate(record) {
    const hourDiff = moment.duration(
      moment(new Date()).diff(moment(record.eklenme_Zamani))
    );

    if (hourDiff.asHours() < 1) {
      return 'Şimdi';
    } else if (hourDiff.asHours() > 24) {
      return moment(record.eklenme_Zamani).format('DD.MM.YYYY');
    } else {
      return parseInt(hourDiff.asHours()) + ' Saat Önce';
    }
  }

  let sortedNotifications = notifications.sort((a, b) => {
    return moment(b.eklenme_Zamani).isAfter(moment(a.eklenme_Zamani)) ? 1 : -1;
  });

  const hasUnreadNotifications =
    sortedNotifications.filter(x => x.durum === 0).length > 0;

  return (
    <div
      style={{
        marginRight: '-12px',
        height: hasUnreadNotifications
          ? 'calc(100% - 155px)'
          : 'calc(100% - 105px)',
        marginBottom: '20px',
      }}
    >
      <Scrollbars
        style={{
          width: '100%',
        }}
      >
        <List>
          {sortedNotifications.length > 0 ? (
            sortedNotifications.map(item => (
              <NotificationListItem
                key={item.id}
                data={item}
                readNotifications={readNotifications}
                setBirthdayClosed={setBirthdayClosed}
                renderDate={renderDate}
              />
            ))
          ) : (
            <EmptyContent text={'Bildiriminiz bulunmamaktadır.'} />
          )}
        </List>
      </Scrollbars>
    </div>
  );
}
