import React, { useEffect, useState, useRef } from 'react';
import { keyframes, styled } from 'ykb-ui';

import { ReactComponent as WelcomeText } from './hosgeldin-text.svg';
import { ReactComponent as CloseIcon } from './close.svg';

const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Main = styled.div`
  font-size: 36px;
  max-width: 85%;
  line-height: 1.15;
  svg {
    max-width: 100%;
    height: auto;
  }
`;

const CloseWrapper = styled.div`
  background: #004990;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  overflow: hidden;
  cursor: pointer;
`;

const colors = ['#FFDC52', '#B0D47B', '#FF8C7A ', '#29BFFF '];

const drift = keyframes`
 0% {
      transform: skewY(10deg) translate3d(-250%, 0, 0);
    }
    100% {
      transform: skewY(-10deg) translate3d(250%, 0, 0);
    }
    `;

const driftRotate = keyframes`
 0% {
      transform: rotateX(0);
    }
    100% {
      transform: rotateX(360deg);
    }`;

const ConfettiItem = styled.div`
  position: absolute;
  z-index: 999;
  bottom: ${props => props.$y}%;
  left: ${props => props.$x}%;
  width: ${props => props.$width}px;
  height: ${props => props.$height}px;

  /* transform: translate3d(20vw, 100vh, 0); */

  .rotate {
    animation: ${driftRotate} 1.4s infinite both ease-in-out;
    width: 100%;
    height: 100%;
    animation-duration: ${props => props.$duration}s;
    animation-delay: ${props => props.$delay}s;
  }
  .askew {
    width: 100%;
    height: 100%;
    animation: ${drift} 1.4s infinite alternate both ease-in-out;
    background-color: ${props => props.$color};
    animation-duration: ${props => props.$duration}s;
    animation-delay: ${props => props.$delay}s;
  }
`;

const List = styled.div`
  &.initial {
    ${ConfettiItem} {
      transform: translate3d(0, 0, 0);
    }
  }
`;

function Confetti({ index, moveX, moveY }) {
  const ref = useRef();

  const width = Math.floor(window.crypto.random() * 10 + 5);
  const height = width * 1;
  const x = Math.floor(window.crypto.random() * 100);
  const y = Math.floor(window.crypto.random() * 100);
  const color = colors[Math.floor(window.crypto.random() * colors.length)];

  const $duration = 1 + window.crypto.random()(100) * 0.01;
  const $delay = -$duration * window.crypto.random()(100) * 0.01;

  useEffect(() => {
    if (ref.current) {
      ref.current.animate(
        [
          { transform: 'translate3d(0,0,0)', opacity: 1 },
          { transform: `translate3d(${moveX}px,${moveY}px,0)`, opacity: 1 },
        ],
        {
          duration: window.crypto.random() * 3000 + 4000,
          iterations: Infinity,
          delay: -(window.crypto.random() * 6000),
        }
      );
    }
  }, []);

  return (
    <ConfettiItem
      $x={x}
      $y={y}
      $width={width}
      $height={height}
      $color={color}
      ref={ref}
      $index={index}
      $duration={$duration}
      $delay={$delay}
    >
      <div class="rotate">
        <div class="askew"></div>
      </div>
    </ConfettiItem>
  );
}

export default function Welcome({ setWelcomeClosed }) {
  const [confettiList] = useState(Array.from(Array(100).keys()));
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    setInitial(false);

    // Close Animation After x second
    setTimeout(() => {
      setWelcomeClosed(true);
    }, 20000);

    // handle esc
    const handleEsc = event => {
      if (event.keyCode === 27) {
        setWelcomeClosed(true);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  function handleClose(e) {
    e.stopPropagation();
    setWelcomeClosed(true);
  }

  let moveY = window.innerHeight;
  let moveX = window.innerWidth;
  return (
    <Wrapper>
      <Main>
        <WelcomeText />
      </Main>

      <List className={initial ? 'initial' : ''}>
        {confettiList.map((i, index) => (
          <Confetti
            key={'index'}
            index={index}
            moveY={moveY}
            moveX={moveX * 0.2}
          />
        ))}
      </List>
      <CloseWrapper onClick={handleClose}>
        <CloseIcon></CloseIcon>
      </CloseWrapper>
    </Wrapper>
  );
}
