import React, { useEffect, useState } from 'react';
import { styled } from 'ykb-ui';
import { ReactComponent as BirthdayGift } from './dogum-gunu-gift.svg';
import { ReactComponent as BirthdayText } from './dogum-gunun-kutlu-olsun.svg';
import { ReactComponent as CloseIcon } from './close.svg';

const SPACE_BETWEEN = 200;

const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Main = styled.div`
  margin-bottom: 60px;
  font-size: 36px;
  max-width: 85%;
  line-height: 1.15;
  svg {
    max-width: 100%;
    height: auto;
  }
`;

const List = styled.div``;

const Circle = styled.div`
  border-radius: 50%;
  width: 0px;
  height: 0px;
  margin-left: -6px;
  margin-top: -6px;
  background-color: blue;
  position: absolute;
  transform: translate(0, 0);
  transform: ${props =>
    `translate(${props.translateX * SPACE_BETWEEN}px, ${props.translateY *
      SPACE_BETWEEN}px)`};
  transition: all 2s;
`;

const ItemContainer = styled.div`
  position: absolute;
  left: ${props => props.$x}px;
  top: ${props => props.$y}px;

  &.initial ${Circle} {
    transform: translate(0, 0);
    width: 12px;
    height: 12px;
    background-color: #f20089;
  }
`;

const CloseWrapper = styled.div`
  background: #004990;
  position: absolute;
  right: 20px;
  top: 70px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  overflow: hidden;
  cursor: pointer;
`;

function FireWorks(props) {
  const [initial, setInitial] = useState(true);
  const circles = Array.from(Array(20).keys());

  useEffect(() => {
    setInitial(false);
  }, []);

  return (
    <ItemContainer
      $x={props.x}
      $y={props.y}
      className={initial ? 'initial' : ''}
    >
      {circles.map((item, index) => {
        return (
          <Circle
            key={'index'}
            translateX={Math.sin((2 * Math.PI * index) / circles.length)}
            translateY={Math.cos((2 * Math.PI * index) / circles.length)}
          ></Circle>
        );
      })}
    </ItemContainer>
  );
}

export default function Birthday({ setBirthdayClosed }) {
  const [fireworks, setFireworks] = useState([]);
  function handleClick(e) {
    setFireworks(fireworks => [...fireworks, { x: e.pageX, y: e.pageY }]);
  }

  useEffect(() => {
    // Creates new randomly placed fireworks every 500ms
    const id = setInterval(() => {
      let randomX = Math.floor(
        window.crypto.random() * (document.body.clientWidth - 100)
      );
      let randomY = Math.floor(
        window.crypto.random() * (window.innerHeight - 200)
      );
      setFireworks(fireworks => [...fireworks, { x: randomX, y: randomY }]);
    }, 250);

    // Close Animation After x second
    setTimeout(() => {
      setBirthdayClosed(true);
    }, 20000);

    // handle esc

    const handleEsc = event => {
      if (event.keyCode === 27) {
        setBirthdayClosed(true);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      clearInterval(id);
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  function handleClose(e) {
    e.stopPropagation();
    setBirthdayClosed(true);
  }
  return (
    <Wrapper onClick={handleClick}>
      <Main>
        <BirthdayText style={{ marginBottom: '10px' }} />
        <BirthdayGift />
      </Main>
      <List>
        {fireworks.map((item, i) => (
          <FireWorks key={'i'} x={item.x} y={item.y}></FireWorks>
        ))}
      </List>
      <CloseWrapper onClick={handleClose}>
        <CloseIcon></CloseIcon>
      </CloseWrapper>
    </Wrapper>
  );
}
