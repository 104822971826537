import React from 'react';
import { styled } from 'ykb-ui';

const Dot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin: 0 5px;
  background: ${props =>
    props.active ? props.theme.color.primary6 : props.theme.color.default3};
`;

const DotContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props => props.theme.color.default2};
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 10px 0 20px 0;
`;

export default function Dots({ count, activeIndex }) {
  const items = [];
  for (let i = 0; i < count; i++) {
    items.push(<Dot key={'dot' + i} active={activeIndex > i} />);
  }
  return <DotContainer>{items}</DotContainer>;
}
