import React from 'react';
import { styled, Icon, Button, Dropdown, Menu, Message } from 'ykb-ui';
import { useHistory } from 'react-router-dom';
import { useAuthentication } from '../../context/Authentication';
import toUpperFirst from '../../utils/toUpperFirst';
import { ReactComponent as IconLogOut } from '../../assets/exit.svg';
import { ReactComponent as IconProfile } from '../../assets/profile.svg';
import IconAvatar from '../../assets/avatar.svg';

const IconWrapper = styled.div`
  text-align: center;
  color: white;
  width: 55px;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    display: none;
  }
`;

const ProfileWrapper = styled(Dropdown)`
  &.noti-ykb {
    border-color: transparent !important;
    border: 0 !important;
    height: -webkit-fill-available;
    display: flex;
    margin-right: 0px;
    border-radius: 0;
    width: 275px;
    align-items: baseline;
    padding-left: 15px;
  }
  padding-left: 10px;
  border: 0px;
  box-shadow: none;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 0px;
  flex-direction: column;
  padding-right: 0px;
  > .text {
    text-align: left;
    display: flex;
    flex: 1;
    align-items: center;
  }
  &.ykb-dropdown-open {
    ${IconWrapper} {
      background-color: ${props => props.theme.color.primary7};
    }
  }
`;

const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;
const ProfileInfoRow = styled.div`
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: white;
  line-height: 1.25;
`;
const Avatar = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  float: left;
  margin-right: 10px;
  background-position: 50% 50%;
`;

const Item = Menu.Item;
const MenuItem = styled(Item)`
  background-color: ${props => props.theme.color.primary7} !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 10px 0px !important;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.color.primary6};
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const SubMenu = styled(Menu)`
  border: 0px !important;
  border-radius: 0px !important;
  margin-top: -4px !important;
  background-color: ${props => props.theme.color.primary7} !important;
  box-shadow: none !important;
  padding: 6px 20px !important;

  ${MenuItem}:last-child {
    border-bottom: 0px;
  }

  margin-right: -1px !important;
  margin-left: 0.3px;

  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    position: fixed;
    left: 0px;
    right: 0px;
  }
`;

const DropButton = styled(Button)`
  padding-left: 10px;
  border: 0px;
  box-shadow: none;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 0px;
  flex-direction: column;
  background-color: ${props => props.theme.color.primary7} !important;
  padding-right: 0px;
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    ${ProfileInfoRow} {
      display: none;
    }
    &.ykb-dropdown-open {
      border-radius: 0px;
    }
  }
`;

const ActionLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
`;

function Overlay({
  redirectToProfile,
  redirectToChangePassword,
  redirectToUploadFile,
  redirectToKvkk,
  callLogOut,
}) {
  return (
    <SubMenu>
      <MenuItem key="1">
        <ActionLink onClick={redirectToProfile}>
          Profilim
          <IconProfile />
        </ActionLink>
      </MenuItem>
      <MenuItem key="4">
        <ActionLink onClick={redirectToUploadFile}>
          Belge Yükleme
          <Icon name="upload" size={26} color="hashtaghashtag#fff" />
        </ActionLink>
      </MenuItem>
      <MenuItem key="5">
        <ActionLink onClick={redirectToKvkk}>
          KVKK Onaylarım
          <Icon name="paper" size={26} color="hashtaghashtag#fff" />
        </ActionLink>
      </MenuItem>
      <MenuItem key="3">
        <ActionLink onClick={redirectToChangePassword}>
          Şifre Değiştir
          <Icon name="lock-alt" size={26} color="hashtaghashtag#fff" />
        </ActionLink>
      </MenuItem>
      <MenuItem key="2">
        <ActionLink onClick={callLogOut}>
          Çıkış Yap
          <IconLogOut />
        </ActionLink>
      </MenuItem>
    </SubMenu>
  );
}

export default function Profile() {
  const history = useHistory();
  const { user, logOut, loggingOut } = useAuthentication();

  function redirectToProfile() {
    history.push('/profil');
  }

  function redirectToChangePassword() {
    history.push('/sifre-degistir');
  }

  function redirectToUploadFile() {
    history.push('/belge-yukleme');
  }

  function redirectToKvkk() {
    history.push('/kvkk-onaylarim');
  }

  function closeCallOut(closeFn) {
    return (
      <React.Fragment>
        <Button disabled={loggingOut} key="cancel" onClick={() => closeFn()}>
          Vazgeç
        </Button>
        <Button
          loading={loggingOut}
          type="primary"
          key="save"
          className="right-floated"
          onClick={async () => {
            await logOut();
            closeFn();
          }}
        >
          Çıkış Yap
        </Button>
      </React.Fragment>
    );
  }

  function callLogOut() {
    Message.prompt({
      title: 'Uyarı',
      content: 'Çıkış yapmak istediğinize emin misiniz?',
      footer: closeCallOut,
    });
  }

  let name = '';
  let familyName = '';
  if (user && user.fullName) {
    const nameArr = user.fullName.split(' ').map(toUpperFirst);
    familyName = nameArr.pop();
    name = nameArr.join(' ');
  } else if (user && user.name) {
    familyName = user.familyName;
    name = user.name;
  }

  return (
    <ProfileWrapper
      overlay={
        <Overlay
          redirectToProfile={redirectToProfile}
          redirectToChangePassword={redirectToChangePassword}
          redirectToUploadFile={redirectToUploadFile}
          redirectToKvkk={redirectToKvkk}
          callLogOut={callLogOut}
        />
      }
      getPopupContainer={() => document.getElementById('popup-container')}
    >
      <DropButton
        className="noti-ykb"
        id="dropdown-profile"
        prefixCls="ykb-dropdown"
      >
        <ProfileInfo>
          <Avatar
            style={{
              backgroundImage: `url("${
                user.profilphoto ? user.profilphoto : IconAvatar
              }")`,
            }}
          />
          <div>
            <ProfileInfoRow title={name}>{name}</ProfileInfoRow>
            <ProfileInfoRow title={familyName}>{familyName}</ProfileInfoRow>
          </div>
        </ProfileInfo>
        <IconWrapper>
          <Icon size={24} name="angle-down-alt" />
        </IconWrapper>
      </DropButton>
    </ProfileWrapper>
  );
}
