import React from 'react';
import { styled, Row, Container, Col, css } from 'ykb-ui';

import loginImage from './login-image.png';
import logoImage from '../../assets/sev-logo.svg';
import ykbLogoImage from '../../assets/ykb-logo.svg';
import backgroundImage from './background.png';

export { default as Dots } from './Dots';

const LoginContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: url(${backgroundImage}) no-repeat top
    ${props => props.theme.color.primary6};
`;

const ContainerRow = styled.div`
  margin-top: 7vh;
  display: table;
  width: 100%;
`;

const ContentColumn = styled.div`
  background-color: white;
  min-height: 60vh;
  display: table-cell;
  width: 51%;

  @media (max-width: 767px) {
    width: 100%;
    border-radius: 10px;
  }
`;

const ImageColumn = styled(ContentColumn)`
  width: 49%;

  ${props =>
    css`
   background: white url(${
      props.$image ? props.$image : loginImage
    }) no-repeat scroll 50% 50%;
     background-size: cover;
  `}

  @media (max-width: 767px) {
    display: none;
  }
`;

const Footer = styled(Col)`
  color: white;
  padding: 0;
  * {
    font-size: 13px;
    line-height: 20px;
    vertical-align: middle;
    display: inline-block;
  }

  a,
  a:hover,
  a:focus {
    color: white;
    text-decoration: none;
  }

  p {
    float: right !important;
    &:first-child {
      float: left !important;
    }

    &:last-child > span:first-child {
      padding-right: 30px;
    }
  }

  .ykb-icon {
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    p {
      width: 100%;
      clear: both;
      text-align: center;
      border-bottom: 1px solid rgb(65, 123, 180);
      padding-bottom: 15px;

      &:first-child {
        span {
          display: block;
          padding: 5px 0;
          width: 100%;
        }
      }

      &:last-child {
        padding-bottom: 15px;
      }
    }
  }

  @media (max-width: 991px) {
    p {
      padding-bottom: 15px;

      &:last-child > span:first-child {
        padding-right: 0;
      }
    }
    .ykb-icon {
      height: 40px;
      width: 40px;
      padding: 10px;
      border-radius: 20px;
      border: 1px solid white;
    }
  }
`;

const FormContainer = styled.div`
  padding: ${props => (props.$fluid ? '20px' : '60px 16.66%')};
  min-height: 600px;
  @media (max-width: 767px) {
    padding: 40px 20px;
  }
`;

export const LogoImg = styled.img.attrs(props => ({
  src: logoImage,
}))`
  height: 45px;
`;

const LogoContainer = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    text-align: center;
  }
`;

export function Logo(...props) {
  return (
    <LogoContainer>
      <LogoImg {...props} />
    </LogoContainer>
  );
}

const YkbLogo = styled.img.attrs(props => ({
  src: ykbLogoImage,
}))`
  height: 20px;
  margin-right: 20px;
  @media (min-width: 768px) {
    float: left;
  }
`;

export const Welcome = styled.h2`
  padding: 30px 0 0 0;
  font-size: 24px;
  font-weight: 600;
`;

export const InfoText = styled.p`
  padding: 0;
  margin: 20px 0 10px 0;
  font-size: 18px;
`;

export default function AuthLayout({ children, image, hideImage }) {
  return (
    <LoginContainer>
      <Container fluid>
        <Row>
          <Col xs={24} smOffset={2} sm={20} lgOffset={3} lg={18}>
            <ContainerRow>
              <ContentColumn xs={24} sm={12}>
                <FormContainer $fluid={hideImage}>{children}</FormContainer>
              </ContentColumn>
              {!hideImage && (
                <ImageColumn xs={24} sm={12} $image={image}></ImageColumn>
              )}
            </ContainerRow>
            <Footer xs={24}>
              <p>
                <span>
                  <YkbLogo alt="Yapı ve Kredi Bankası A.Ş." />
                </span>
                Yapı ve Kredi Bankası A.Ş. Emekli Sandığı Vakfı
              </p>
              <p>
                <span>
                  <a
                    href="https://www.yapikrediemeklisandigi.org/hakkimizda/id=34"
                    target="_blank"
                  >
                    <span
                      style={{ marginTop: -10 }}
                      className="hidden-xs hidden-sm"
                    >
                      Çerez Politikamız
                    </span>
                  </a>
                </span>
                {/* <span>
                  <a href="tel://4444444">
                    <Icon name="phone" size={20} />{' '}
                    <span className="hidden-xs hidden-sm">444 - 44 - 44</span>
                  </a>
                </span>
                <span>
                  <a href="mailto:destek@ykemeklisandigi.com.tr">
                    <Icon name="mail" size={20} />{' '}
                    <span className="hidden-xs hidden-sm">
                      destek@ykemeklisandigi.com.tr
                    </span>
                  </a>
                </span> */}
              </p>
            </Footer>
          </Col>
        </Row>
      </Container>
    </LoginContainer>
  );
}
