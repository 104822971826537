import React, { useState } from 'react';
import { Button, Dropdown, Message, Typography, styled } from 'ykb-ui';
import { useHistory } from 'react-router-dom';
import NotificationList from './NotificationList';
import { useNotification } from '../../context/NotificationContext';
import { ReactComponent as IconNotifyEmpty } from '../../assets/icons/notif-empty.svg';
import { ReactComponent as IconNotify } from '../../assets/icons/notif.svg';
import BirthdayAnimation from '../../components/Animations/Birthday';

const IconWrapper = styled.div`
  text-align: center;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const IconNotifyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const NotifyWrapperValue = styled.div`
  left: 13px;
  position: absolute;
  font-size: 12px;
  top: 1px;
  font-weight: bold;
  width: 10px;
`;

const Circular = styled.div`
  position: absolute;
  font-size: 9px;
  top: 2px;
  right: 0px;
  width: 13px;
  height: 13px;
  background: ${props => props.theme.color.danger6};
  border-radius: 50%;
  overflow: hidden;
`;

const NotificationWrapper = styled(Dropdown)`
  padding: 0px;
  border: 0px;
  box-shadow: none;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 0px;
  flex-direction: column;
  > span {
    text-align: left;
    display: flex;
    flex: 1;
    align-items: center;
  }
`;

const DropButton = styled(Button)`
  &.noti-ykb {
    border-color: transparent !important;
    border-radius: 30px !important;
    margin-right: 20px !important;
  }
  border: 0px;
  box-shadow: none;
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin-right: 0px;
  flex-direction: column;
  padding-right: 0px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary7} !important;
  margin-right: 20px;
`;

const Popup = styled.div`
  width: 380px;
  max-width: 78vw;
  background: white;
  border-radius: 10px;
  padding: 20px;
  margin-top: 6px;
  right: 45px;
  position: relative;
  height: 75vh;
  max-height: 600px;
  min-height: 300px;

  @media (max-width: 1199px) {
    right: -20px;
  }
  :after {
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 60px;
    z-index: -60;
    content: '';
    pointer-events: none;
  }
`;

const NotificationTitle = styled(Typography)`
  font-size: 18px;
  color: ${props => props.theme.color.neutral11};
  font-weight: 500;
  line-height: 24px;
  justify-content: space-between;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`;

const UnreadInfo = styled.div`
  background-color: ${props => props.theme.color.secondary1};
  font-size: 12px;
  padding: 10px;
  margin-bottom: 10px;
`;

const MarkReadAll = styled.span`
  color: ${props => props.theme.color.default6};
  text-align: right;
  font-size: 13px;
  cursor: pointer;
`;

const NotificationFooter = ({ closeFn, readNotifications, notifications }) => {
  const handleSave = async () => {
    const notificationIds = notifications.map(notification => notification.id);
    await readNotifications(notificationIds);
    closeFn();
  };

  return (
    <React.Fragment>
      <Button key="cancel" onClick={closeFn}>
        Vazgeç
      </Button>
      <Button
        type="primary"
        key="save"
        className="right-floated"
        onClick={handleSave}
      >
        İşaretle
      </Button>
    </React.Fragment>
  );
};

const DropdownContent = ({
  notifications,
  readNotifications,
  readAll,
  setBirthdayClosed,
  history,
}) => (
  <Popup>
    <NotificationTitle level={4}>
      Bildirimler
      <MarkReadAll onClick={readAll}>Hepsini Okundu İşaretle</MarkReadAll>
    </NotificationTitle>
    {notifications.filter(x => x.durum === 0).length > 0 && (
      <UnreadInfo>
        <b>{notifications.filter(x => x.durum === 0).length} adet</b> okunmamış
        bildiriminiz bulunuyor.
      </UnreadInfo>
    )}
    <NotificationList
      notifications={notifications}
      readNotifications={readNotifications}
      setBirthdayClosed={setBirthdayClosed}
    />
    <Button
      type="secondary"
      className="fluid"
      onClick={() => history.push('/bildirimler')}
    >
      Tümünü Gör
    </Button>
  </Popup>
);

export default function Notification() {
  const { notifications, readNotifications } = useNotification();
  const history = useHistory();
  const [birthdayClosed, setBirthdayClosed] = useState(true);

  function closeRead(closeFn) {
    return (
      <NotificationFooter
        closeFn={closeFn}
        readNotifications={readNotifications}
        notifications={notifications}
      />
    );
  }

  const readAll = () => {
    Message.prompt({
      title: 'Uyarı',
      content: 'Tüm bildirimleri okundu olarak işaretle',
      footer: closeRead,
    });
  };

  const hasUnreadNotifications =
    notifications.filter(x => x.durum === 0).length > 0;

  return (
    <>
      {!birthdayClosed && (
        <BirthdayAnimation setBirthdayClosed={setBirthdayClosed} />
      )}
      <NotificationWrapper
        overlay={
          <DropdownContent
            notifications={notifications}
            readNotifications={readNotifications}
            readAll={readAll}
            setBirthdayClosed={setBirthdayClosed}
            history={history}
          />
        }
        getPopupContainer={() => document.getElementById('popup-container')}
      >
        <DropButton className="noti-ykb" id="dropdown-notification">
          <IconWrapper>
            {hasUnreadNotifications ? (
              <IconNotifyWrapper>
                <IconNotify />
                <Circular></Circular>
                <NotifyWrapperValue>
                  {notifications.filter(x => x.durum === 0).length}
                </NotifyWrapperValue>
              </IconNotifyWrapper>
            ) : (
              <IconNotifyEmpty />
            )}
          </IconWrapper>
        </DropButton>
      </NotificationWrapper>
    </>
  );
}
