import React from 'react';
import Helmet from 'react-helmet';
import AuthLayout from '../../components/AuthLayout';
import Table from '../../components/Survey/Table';

export default function SurveyForm({ id }) {
  return (
    <AuthLayout hideImage={true}>
      <Helmet>
        <title>YKB Emekli Sandiği Vakfı Anket</title>
      </Helmet>
      <Table id={id} />
    </AuthLayout>
  );
}
