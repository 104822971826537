import React from 'react';
import callApi from '../utils/callApi';

const NotificationContext = React.createContext();

const initialState = {
  notifications: [],
  loading: false,
  error: null,
};

function notificationReducer(state, action) {
  switch (action.type) {
    case 'GET_NOTIFICATIONS': {
      return { ...state, loading: true };
    }
    case 'GET_NOTIFICATIONS_SUCCESS': {
      return {
        ...state,
        loading: false,
        notifications: [...action.payload],
      };
    }
    case 'GET_NOTIFICATIONS_FAIL': {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }

    case 'READ': {
      const { notifications } = state;
      const list = notifications.map(item => {
        if (action.payload.includes(item.id)) {
          item.durum = 1;
        }
        return item;
      });

      return { ...state, loading: false, notifications: list };
    }

    case 'DELETE': {
      const { notifications } = state;
      const list = notifications.filter(
        item => !action.payload.includes(item.id)
      );
      return { ...state, loading: false, notifications: list };
    }
    default: {
      throw new Error(`No such action on notification: ${action.type}`);
    }
  }
}

function useNotification() {
  const context = React.useContext(NotificationContext);

  if (!context) {
    throw new Error(
      `useNotification hook must be used within an NotificationProvider`
    );
  }

  const [state, dispatch] = context;

  const getNotifications = data =>
    dispatch({ type: 'GET_NOTIFICATIONS', payload: data });

  const getNotificationsSuccess = data =>
    dispatch({ type: 'GET_NOTIFICATIONS_SUCCESS', payload: data });

  const getNotificationsFail = data =>
    dispatch({ type: 'GET_NOTIFICATIONS_FAIL', payload: data });

  const read = data => dispatch({ type: 'READ', payload: data });
  const _delete = data => dispatch({ type: 'DELETE', payload: data });

  const fetchNotifications = async function(data) {
    getNotifications();
    try {
      const response = await callApi({
        endpoint: '/Notification/GetNotification',
        method: 'GET',
      });
      const mappedData = response.map(item => {
        return { ...item, key: item.id };
      });
      getNotificationsSuccess(mappedData);
    } catch (ex) {
      getNotificationsFail(ex);
      throw ex;
    }
  };

  const readNotifications = async function(data) {
    try {
      await callApi({
        endpoint: '/Notification/SetReadNotifications',
        method: 'POST',
        body: { id: data },
      });
      read(data);
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  };

  const deleteNotifications = async function(data) {
    try {
      await callApi({
        endpoint: '/Notification/SetDeleteNotifications',
        method: 'POST',
        body: { id: data },
      });
      _delete(data);
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  };

  return {
    notifications: state.notifications,
    loginError: state.error,
    loading: state.loading,
    fetchNotifications,
    readNotifications,
    deleteNotifications,
  };
}

function NotificationProvider(props) {
  const [state, dispatch] = React.useReducer(notificationReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <NotificationContext.Provider value={value} {...props} />;
}

export { NotificationProvider, useNotification };
