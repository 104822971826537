import React, { useState, useEffect } from 'react';
import { styled, Typography, Icon, Button, Col, Spin } from 'ykb-ui';
import ESVImg from '../../assets/ESVImg.png';

const Cookie = styled.div`
  background-color: #f2f2f2;
  position: sticky;
  top: 0px;
  z-index: 1000;
  min-height: 70px;
  display: flex !important;
  align-items: center;
`;

const Buttons = styled(Button)`
  color: #fff !important;
  background-color: #00a8ff !important;
  border: 0 !important;
  width: 60px !important;
`;

const CookieText = styled(Typography.Text)`
  font-size: 12px;
  line-height: 1.2;
  color: #000;
`;

export default function CookiePolicy() {
  const [showMobilAppCard, setShowMobilAppCard] = useState(false);

  function getMobileOperatingSystem() {
    let userAgent = navigator.userAgent || window.opera; // Windows Phone must come first because its UA also contains "Android"

    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    } // iOS detection from: http://stackoverflow.com/a/9039885/177710

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }

  let operatinSystem = getMobileOperatingSystem();

  useEffect(() => {
    if (operatinSystem === 'iOS' || operatinSystem === 'Android') {
      setShowMobilAppCard(true);
    }
  }, []);

  function MobilAppClick() {
    if (operatinSystem === 'iOS') {
      window.open('https://apps.apple.com/tr/app/esv-mobil/id1609917748');
    }
    if (operatinSystem === 'Android') {
      window.open('https://play.google.com/store/apps/details?id=com.ykb.esv');
    }
  }

  return (
    showMobilAppCard && (
      <Cookie className="visible-xs visible-sm">
        <Col sm={6} xs={2}>
          <Icon
            onClick={() => setShowMobilAppCard(false)}
            name="close"
            size="xsmall"
          />
        </Col>
        <Col sm={2} xs={4}>
          <img
            style={{ height: 50, borderRadius: 10, marginTop: 5 }}
            src={ESVImg}
            alt="banner"
          />
        </Col>
        <Col sm={7} xs={12} style={{ marginLeft: 5 }}>
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            ESV Mobil
          </Typography.Title>
          <CookieText>
            <Spin>Uygulamamızı indirmek için tıklayınız.</Spin>
          </CookieText>
        </Col>
        <Col sm={1} xs={4}>
          <Buttons size="small" onClick={() => MobilAppClick()}>
            İndir
          </Buttons>
        </Col>
      </Cookie>
    )
  );
}
