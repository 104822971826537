import React, { useState, useEffect } from 'react';
import Page from '../components/Page';
import { Collapse } from 'ykb-ui';
import callApi from '../utils/callApi';

const Panel = Collapse.Panel;
export default function SSS() {
  const [activeKey, setActiveKey] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    getSSSData();
  }, []);

  const getSSSData = async () => {
    const response = await callApi({
      endpoint: 'SSS/GetSSS',
    });
    setData(response);
  };

  const onChange = key => {
    setActiveKey(key);
  };

  function createMarkup(text) {
    return { __html: text };
  }

  return (
    <Page title="Sıkça Sorulan Sorular">
      <Page.Content>
        <Collapse onChange={onChange} activeKey={activeKey}>
          {data.map((item, i) => {
            return (
              <Panel
                header={
                  <div
                    dangerouslySetInnerHTML={createMarkup(item.question)}
                  ></div>
                }
                key={'i'}
              >
                <div dangerouslySetInnerHTML={createMarkup(item.answer)}></div>
              </Panel>
            );
          })}
        </Collapse>
      </Page.Content>
    </Page>
  );
}
