import React, { useState, useEffect } from 'react';
import { styled, Modal, Button, InfoBox } from 'ykb-ui';
import CountDown from '../CountDown';
import callApi from '../../utils/callApi';
import { useAuthentication } from '../../context/Authentication';

const CountDownBox = styled(Button)`
  /* default light 90 */
  background: ${props => props.theme.color.default1};
  padding: 10px !important;
  display: inline-block;
  margin: 20px 0px;
  pointer-events: none;
  .countdown-timer {
    display: inline-block;
  }
`;

const IDLE_TIME_TO_SHOW_MODAL = 180;
const TIME_COUNTDOWN_START = 120;

export default function IdleTimer() {
  const { logOut, loggingOut } = useAuthentication();
  const [countDownStart, setCountDownStart] = useState(null);
  const [isCountDownComplete, setIsCountDownComplete] = useState(false);

  const [visible, _setVisible] = useState(false);
  // Created ref for event listener usage inside useEffect
  const visibleRef = React.useRef(visible);
  const setVisible = data => {
    visibleRef.current = data;
    _setVisible(data);
  };

  // this is used for restart countdown
  const [timerVersion, _setTimerVersion] = useState(0);
  // Created ref for event listener usage inside useEffect
  const timerVersionRef = React.useRef(timerVersion);
  const setTimerVersion = data => {
    timerVersionRef.current = data;
    _setTimerVersion(data);
  };

  async function ResetServerTimer() {
    await callApi({
      endpoint: 'Auth/TokenExpireControl',
    });
  }

  useEffect(() => {
    setCountDownStart(null);
    setVisible(false);
    const timer = setTimeout(() => {
      setCountDownStart(TIME_COUNTDOWN_START);
      setVisible(true);
    }, IDLE_TIME_TO_SHOW_MODAL * 1000);
    return () => clearTimeout(timer);
  }, [timerVersion]);

  // Mouse event listener
  useEffect(() => {
    if (!window.onServiceCall) {
      window.onServiceCall = date => {
        setTimerVersion(timerVersionRef.current + 1);
      };
    }
  }, []);

  const renderFooter = () => {
    if (!isCountDownComplete) {
      return [
        <Button
          loading={loggingOut}
          key="back"
          onClick={() => {
            logOut();
          }}
        >
          Çıkış Yap
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            setTimerVersion(timerVersion + 1);
            ResetServerTimer();
          }}
        >
          Devam Et
        </Button>,
      ];
    } else {
      return [
        <Button
          key="back"
          type="primary"
          loading={loggingOut}
          onClick={() => {
            logOut();
          }}
        >
          Giriş Yap
        </Button>,
      ];
    }
  };

  return (
    <Modal
      visible={visible}
      title="Oturumu Sonlandırma"
      onClose={() => {
        ResetServerTimer();
        setTimerVersion(timerVersion + 1);
      }}
      closable={!isCountDownComplete}
      footer={renderFooter()}
    >
      <div>
        Bir süredir işlem yapmadığınız için oturumunuzdan çıkış yapılacaktır.
        Uygulamayı kullanmaya devam etmek istiyor musunuz?
      </div>
      <CountDownBox type="ghost">
        Kalan Süre{' '}
        <CountDown
          from={countDownStart}
          onComplete={e => {
            if (countDownStart !== null) {
              setCountDownStart(null);
              setIsCountDownComplete(true);
            }
          }}
        />
      </CountDownBox>
      {isCountDownComplete && (
        <InfoBox type="warning">
          Belirtilen süre içinde bir aksiyon almadığınız için çıkış yapılmıştır.
          Tekrar işlem yapmak için lütfen giriş yapınız.
        </InfoBox>
      )}
    </Modal>
  );
}
