import React, { useState, useEffect } from 'react';
import { Form, Table, Spin, Button, RadioGroup } from 'ykb-ui';
import Typography from 'ykb-ui/lib/Typography';
import callApi, { queryString } from '../../utils/callApi';
import { useHistory } from 'react-router';

const columns = [
  {
    title: '',
    dataIndex: 'soru',
    key: 'soru',
  },
  {
    title: 'Kesinlikle Katılmıyorum',
    key: 'action',
    render: (text, record) => (
      <RadioGroup
        name={`${record.id}`} // soru kodu
        validation={[{ required: true }]}
        items={[{ textLabel: '', value: '1' }]} // value cevap kodu
        style={{
          textAlign: 'center',
        }}
      />
    ),
  },
  {
    title: 'Katılmıyorum',
    key: 'action',
    render: (text, record) => (
      <RadioGroup
        name={`${record.id}`}
        validation={[{ required: true }]}
        items={[{ textLabel: '', value: '2' }]}
        style={{
          textAlign: 'center',
        }}
      />
    ),
  },
  {
    title: 'Ne Katılıyorum / \n Ne Katılmıyorum',
    key: 'action',
    render: (text, record) => (
      <RadioGroup
        styled="textAlign: 'center'"
        name={`${record.id}`}
        validation={[{ required: true }]}
        items={[{ textLabel: '', value: '3' }]}
        style={{
          textAlign: 'center',
        }}
      />
    ),
  },
  {
    title: 'Katılıyorum',
    key: 'action',
    render: (text, record) => (
      <RadioGroup
        name={`${record.id}`}
        validation={[{ required: true }]}
        items={[{ textLabel: '', value: '4' }]}
        style={{
          textAlign: 'center',
        }}
      />
    ),
  },
  {
    title: 'Kesinlikle Katılıyorum',
    key: 'action',
    render: (text, record) => (
      <RadioGroup
        name={`${record.id}`}
        validation={[{ required: true }]}
        items={[{ textLabel: '', value: '5' }]}
        style={{
          textAlign: 'center',
        }}
      />
    ),
  },
];
export default function TableSurvey({ id }) {
  // datayı servisten çek
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // service call ve data set et
    setSurveyListData();
  }, []);

  const setSurveyListData = async () => {
    try {
      setLoading(true);
      const reponse = await getSurveyData();

      if (reponse.status == 2) {
        history.push('/islem-basarili');
        return;
      }
      if (reponse.status == 3) {
        history.push('/Uyari');
        return;
      }

      setData(reponse);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const getSurveyData = async () => {
    const response = await callApi({
      endpoint:
        'Anket/GetById?' +
        queryString({
          Id: id,
        }),
      method: 'GET',
    });

    return response;
  };

  const saveSurvey = async function(mapData) {
    try {
      setLoading(true);
      const response = await callApi({
        endpoint: '/Anket/Save',
        body: {
          cevap: mapData,
          Id: id,
        },
        method: 'POST',
      });

      if (response) {
        history.push('/islem-basarili');
      }
    } catch {
      Notification.error('Hata oluştu.', 8);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (e, error, values) => {
    if (!error) {
      let mapData = Object.keys(values).map(key => {
        return {
          CevapKod: values[key],
          SoruId: key,
        };
      });

      saveSurvey(mapData);
    }
  };

  return (
    data && (
      <div>
        <Spin spinning={loading}>
          <Typography.Title level={3}>{data && data.title}</Typography.Title>
          <Typography.Paragraph>{data && data.desc} </Typography.Paragraph>
          <div style={{ marginBottom: 16 }}>
            <Form onSubmit={onSubmit} colSpan={{ span: 24 }}>
              <Form.Item>
                <Table
                  columns={columns}
                  data={data && data.anketSoru}
                  bordered={false}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="htmlSubmit"
                  className="right-floated"
                >
                  Anketi Tamamla
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </div>
    )
  );
}
