import { styled, keyframes } from 'ykb-ui';
import backgroundImage from '../../assets/ask.png';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const AskToHRMenuContainer = styled.div`
  position: absolute;
  bottom: 50px;
  text-align: left;
`;

export const AskToHRContainer = styled.div`
  position: fixed;
  float: right;
  position: sticky;
  right: 35px;
  bottom: 10px;
  z-index: 999;
  height: 50px;
  animation: ${fadeIn} 0.5s;

  button {
    text-align: left;
    width: 160px;
    box-shadow: 0 7px 10px -5px #ccc;
    display: block;
    margin-bottom: 10px;
  }

  button.ask-btn {
    height: 50px;
    padding-left: 60px;
    font-weight: 700;
    box-shadow: none;
    &:before {
      content: ' ';
      width: 29px;
      height: 29px;
      background: url(${backgroundImage});
      top: 10px;
      left: 20px;
      position: absolute;
    }
  }
`;
