import React from 'react';
import Helmet from 'react-helmet';
import AuthLayout from '../../components/AuthLayout';
import Success from '../../components/Survey/Success';

export default function SurveyForm({ id }) {
  return (
    <AuthLayout hideImage={true}>
      <Helmet>
        <title>YKB Emekli Sandığı Vakfı Anket Sonuç</title>
      </Helmet>
      <Success id={id} />
    </AuthLayout>
  );
}
