import React from 'react';
import { styled, Container, Typography, Button, Icon } from 'ykb-ui';
import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';

import Content from './Content';
import SplitedContent from './SplitedContent';

const PageWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

const Title = styled(Typography.Title)`
  color: ${props => props.theme.color.neutral11} !important;
  font-weight: 500 !important;
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  line-height: 1 !important;
  @media (max-width: 767px) {
    line-height: 26px !important;
    font-size: 20px !important;
  }
`;

const Group = styled.div`
  margin-bottom: 30px;
  line-height: 1.35;
  position: relative;
`;

const GroupTitle = styled(Typography.Title)`
  color: ${props => props.theme.color.neutral11} !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  line-height: 1.33 !important;
  font-size: 18px !important;
  position: relative;
`;

const GroupContent = styled.div`
  background: white;
  width: 100%;
  border-radius: 10px;
`;
const Link = styled(Button)`
  position: absolute !important;
  right: 0px !important;
  top: -4px !important;
  font-size: 13px;
  font-weight: 500;
`;

const TitleContainer = ({ url, children, ...rest }) => {
  const history = useHistory();
  return (
    <GroupTitle {...rest}>
      {url && (
        <Link link="#" onClick={() => history.push(url)}>
          İncele
          <Icon
            name="arrow-right"
            size={24}
            style={{
              marginLeft: '4px',
              display: 'inline-block',
              position: 'relative',
              top: '5px',
            }}
          />
        </Link>
      )}
      {children}
    </GroupTitle>
  );
};

export default function Page({
  videoBanner,
  children,
  title,
  hideTitle,
  disableHelmet,
}) {
  const VideoBanner = videoBanner;
  return (
    <PageWrapper>
      {!disableHelmet && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      {videoBanner && <VideoBanner></VideoBanner>}
      {!hideTitle && title && <Title level={2}>{title}</Title>}
      {children}
    </PageWrapper>
  );
}

Page.Content = Content;
Page.SplitedContent = SplitedContent;
Page.Group = Group;
Page.GroupTitle = TitleContainer;
Page.GroupContent = GroupContent;
