import React from 'react';
import callApi from '../../utils/callApi';
import { useHistory } from 'react-router-dom';

const AuthenticatonContext = React.createContext();

const initialState = {
  authenticated: false,
  user: null,
  loggingIn: false,
  loginError: null,
  verifyingPassword: false,
  loggingOut: false,
};

function authenticationReducer(state, action) {
  switch (action.type) {
    case 'LOGIN': {
      return { ...state, loggingIn: true };
    }
    case 'LOGIN_SUCCESS': {
      return { ...state, loggingIn: false, user: action.payload };
    }
    case 'LOGIN_FAIL': {
      return {
        ...state,
        loggingIn: false,
        user: null,
        loginError: action.payload.error,
      };
    }
    case 'VERIFY_PASSWORD': {
      return { ...state, verifyingPassword: true };
    }
    case 'VERIFY_PASSWORD_SUCCESS': {
      return { ...state, verifyingPassword: false };
    }
    case 'VERIFY_PASSWORD_FAIL': {
      return {
        ...state,
        verifyingPassword: false,
        user: null,
        verifyPasswordError: action.payload.error,
      };
    }
    case 'LOGOUT_START': {
      return { ...state, loggingOut: true };
    }
    case 'LOGOUT_SUCCESS': {
      return { ...state, loggingOut: false, user: null };
    }
    // same as success now we can take actions and controls later
    case 'LOGOUT_FAIL': {
      return {
        ...state,
        loggingOut: false,
        user: null, // set user null and logout anyway
      };
    }

    case 'PRIVACY_POLICY_CONFIRMED': {
      return {
        ...state,
        user: {
          ...state.user,
          privacyPolicy: state.user.privacyPolicy.filter(
            x => x.id != action.payload
          ),
        },
      };
    }

    case 'CHANGE_PROFILE_PICTURE': {
      return {
        ...state,
        user: { ...state.user, profilphoto: action.payload },
      };
    }
    default: {
      throw new Error(`No such action on authentication: ${action.type}`);
    }
  }
}

function useAuthentication() {
  const context = React.useContext(AuthenticatonContext);

  if (!context) {
    throw new Error(
      `useAuthentication hook must be used within an AuthenticationProvider`
    );
  }

  const [state, dispatch] = context;

  const loginStart = data => dispatch({ type: 'LOGIN', payload: data });

  const loginSuccess = data =>
    dispatch({ type: 'LOGIN_SUCCESS', payload: data });

  const loginFail = data => dispatch({ type: 'LOGIN_FAIL', payload: data });

  const verifyPasswordStart = data =>
    dispatch({ type: 'VERIFY_PASSWORD', payload: data });

  const verifyPasswordSuccess = data =>
    dispatch({ type: 'VERIFY_PASSWORD_SUCCESS', payload: data });

  const verifyPasswordFail = data =>
    dispatch({ type: 'VERIFY_PASSWORD_FAIL', payload: data });

  const logOutStart = data => dispatch({ type: 'LOGOUT_START', payload: data });

  const logOutSuccess = data =>
    dispatch({ type: 'LOGOUT_SUCCESS', payload: data });

  const logOutFail = data => dispatch({ type: 'LOGOUT_FAIL', payload: data });

  const privacyPolicyConfirmed = data => {
    return dispatch({ type: 'PRIVACY_POLICY_CONFIRMED', payload: data });
  };

  const changeProfilePicture = data => {
    return dispatch({ type: 'CHANGE_PROFILE_PICTURE', payload: data });
  };

  const history = useHistory();

  const mapUser = user => {
    return {
      identityNumber: user.tc,
      name: user.ad || '',

      familyName: user.soyad || '',

      status: user.status,
      privacyPolicy: user.textTemp,
      statusCode: user.halkod,
      profilphoto: user.profilphoto,
      isFirstLoginMessage: user.isFirstLoginMessage,
      isBirthdayMessage: user.isBirthdayMessage,
      dashboardList: user.dashboardList,
      authList: user.authList,
    };
  };

  const login = async function(data) {
    verifyPasswordStart();
    try {
      const response = await callApi({
        endpoint: '/Auth/CheckPass',
        method: 'POST',
        body: data,
      });
      verifyPasswordSuccess(response);
      history.push('/login/otp', {
        identityNumber: response.tc,
        name: response.ad,
        phoneNumber: response.phoneNumber,
        referenceCode: response.referenceCode,
      });
    } catch (ex) {
      verifyPasswordFail(ex);
      throw ex;
    }
  };

  const sendOtp = async function(identityNumber) {
    const response = await callApi({
      endpoint: '/Auth/SendSmsCodeUser',
      method: 'POST',
      body: { identityNumber },
    });
    return response;
  };

  const sendRegisterOtp = function(identityNumber) {
    return callApi({
      endpoint: '/Auth/RegisterSendSmsCode',
      method: 'POST',
      body: { identityNumber },
    });
  };

  const checkOtp = async function(data) {
    loginStart();
    try {
      const response = await callApi({
        endpoint: '/Auth/LoginCheckSms',
        method: 'POST',
        body: data,
      });
      loginSuccess(response);
    } catch (ex) {
      loginFail(ex);
      throw ex;
    }
  };

  const forgotPassword = async function(forgotPassword) {
    const response = await callApi({
      endpoint: '/Auth/SendSmsForgotPasswordUser',
      method: 'POST',
      body: { ...forgotPassword },
    });

    if (response.smsNumber) {
      response.smsNumber =
        '0*** *** ' + response.smsNumber.slice(response.smsNumber.length - 4);
    }
    return response;
  };

  const checkAuth = async function() {
    loginStart();
    try {
      const response = await callApi({
        endpoint: '/Auth/TokenExpireControl',
        method: 'GET',
        notifyErrors: false,
      });
      loginSuccess(mapUser(response));
    } catch (ex) {
      loginFail({ error: 'Oturumunuz sona ermiştir.' });
    }
  };

  const logOut = async function() {
    logOutStart();
    try {
      await callApi({
        endpoint: '/Auth/Logout',
        method: 'POST',
        notifyErrors: false,
      });

      history.push('/login');
      setTimeout(() => {
        // directly calling and setting user null leads loading
        logOutSuccess();
      }, 0);
    } catch (ex) {
      console.error('Logout error', ex);
      history.push('/login');
      setTimeout(() => {
        // directly calling and setting user null leads loading
        logOutFail(ex);
      }, 0);
    }
  };

  return {
    user: state.user,
    loginError: state.loginError,
    loggingIn: state.loggingIn,
    verifyingPassword: state.verifyingPassword,
    login,
    checkOtp,
    sendOtp,
    sendRegisterOtp,
    forgotPassword,
    checkAuth,
    loggingOut: state.loggingOut,
    logOut,
    privacyPolicyConfirmed,
    changeProfilePicture,
    // dispatch,
    // logout
  };
}

function AuthenticationProvider(props) {
  const [state, dispatch] = React.useReducer(
    authenticationReducer,
    initialState
  );
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <AuthenticatonContext.Provider value={value} {...props} />;
}

export { AuthenticationProvider, useAuthentication };
