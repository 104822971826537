import React from 'react';
import { styled } from 'ykb-ui';
import AskButton from '../AskButton';

const Section = styled.section`
  width: 100%;
  flex: 1 0 auto;
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    padding: 0px 6px;
  }
`;
export default function Main({ children }) {
  return (
    <Section>
      {children}

      <AskButton />
    </Section>
  );
}
