import React, { useEffect, useState } from 'react';
import { Form, styled, Typography, Button, Select, Spin } from 'ykb-ui';
import callApi, { queryString } from '../../utils/callApi';

const Title = styled(Typography)`
  && {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: white;
    > * {
      color: white;
    }
  }
`;

const FooterForm = styled(Form)`
  && {
    label {
      color: white;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const ListButton = styled(Button)`
  && {
    background-color: ${props => props.theme.color.secondary6};
    color: white;
    border: transparent;
    &:hover {
      background-color: ${props => props.theme.color.secondary7};
    }
    &:active {
      background-color: ${props => props.theme.color.secondary8};
    }
  }
`;

const FormItem = Form.Item;

export default function Top() {
  const formRef = React.useRef(null);
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [institutionsCategory, setInstitutionsCategory] = useState([]);

  const getCities = async function(institutionType) {
    try {
      setLoading(true);
      const response = await callApi({
        endpoint:
          'Kurum/GetCities?' +
          queryString({
            institutionType: institutionType,
          }),
      });
      let mappedData = response.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setCities(mappedData);
    } finally {
      setLoading(false);
    }
  };

  const getDistricts = async function(cityId, institutionsType) {
    try {
      setLoading(true);
      const response = await callApi({
        endpoint:
          'Kurum/GetDistrict?' +
          queryString({
            cityId: cityId,
            institutionType: institutionsType,
          }),
      });
      let mappedData = response.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setDistricts(mappedData);
    } finally {
      setLoading(false);
    }
  };

  const getCategory = async function() {
    try {
      setLoading(true);
      const response = await callApi({
        endpoint: 'Kurum/GetAllCategory',
      });
      let mappedData = response.map(item => {
        return {
          label: item.baslik,
          value: item.kurumKatId,
        };
      });

      setInstitutionsCategory(mappedData);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e, error, values) => {
    e.preventDefault();
    if (!error) {
      window.open(
        'https://www.yapikrediemeklisandigi.org/ESV/saglik-islemleri/anlasmali-kurum/id=' +
          (values.institutionsCategory ? values.institutionsCategory : '9') +
          '&sehir=' +
          (values.city ? values.city : '34') +
          '&ilce=' +
          (values.districts ? values.districts : '0'),
        '_blank'
      );
    }
  };

  function onValuesChange(changedValues) {
    if (changedValues.institutionsCategory) {
      getCities(changedValues.institutionsCategory);
      getDistricts(
        formRef.current.getFieldsValue().city,
        changedValues.institutionsCategory
      );
    }
    if (changedValues.city) {
      getDistricts(
        changedValues.city,
        formRef.current.getFieldsValue().institutionsCategory
      );
    }
  }

  useEffect(() => {
    getCategory();
    getCities(0);
  }, []);

  return (
    <div id="contracted-institution">
      <Spin spinning={loading}>
        <FooterForm
          ref={formRef}
          colSpan={{ xs: 24, sm: 6, md: 6, lg: 6 }}
          onSubmit={handleSubmit}
          onValuesChange={onValuesChange}
        >
          <FormItem colSpan={{ span: 24 }}>
            <Title level={5}>Anlaşmalı Kurumlar</Title>
          </FormItem>
          <FormItem label="Kurum">
            <Select data={institutionsCategory} name="institutionsCategory" />
          </FormItem>
          <FormItem label="Şehir">
            <Select data={cities} name="city" />
          </FormItem>
          <FormItem label="İlçe">
            <Select data={districts} name="district" />
          </FormItem>
          <FormItem label="&nbsp;">
            <ListButton className="fluid" htmlType="submit">
              Listele
            </ListButton>
          </FormItem>
        </FooterForm>
      </Spin>
    </div>
  );
}
